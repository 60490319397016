import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import FooterBar from "./components/common/FooterBar";
import NavBar from "./components/common/NavBar";
import { dateFormat, moneyFormat } from "./utils/global";
import store from "./store";
import permissionDirective from "./directive/permission";
import {
  Button,
  Icon,
  Tab,
  Tabs,
  Form,
  Checkbox,
  Field,
  Cell,
  CellGroup,
  Popover,
  Image,
  Sticky,
  Col,
  Row,
  Dialog,
  Toast,
  Loading,
  Notify,
  Empty,
  Tabbar,
  TabbarItem,
  RadioGroup,
  Radio,
  Overlay,
  CheckboxGroup,
  Popup,
  List,
  Divider,
  Picker,
  DatetimePicker,
  Uploader,
  ImagePreview,
  Calendar,
  Search,
  Switch,
  ActionSheet,
} from "vant";

Vue.use(ActionSheet);
Vue.use(Search);
Vue.use(ImagePreview);
Vue.use(Uploader);
Vue.use(DatetimePicker);
Vue.use(Picker);
Vue.use(Divider);
Vue.use(List);
Vue.use(CheckboxGroup);
Vue.use(Overlay);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Button);
Vue.use(Icon);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Checkbox);
Vue.use(Field);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Popover);
Vue.use(Image);
Vue.use(Sticky);
Vue.use(Col);
Vue.use(Row);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Form);
Vue.use(Loading);
Vue.use(Notify);
Vue.use(Empty);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Popup);
Vue.use(Calendar);
Vue.use(Switch);
Vue.component("FooterBar", FooterBar);
Vue.component("VanNavBar", NavBar);

// // // 窗口的安卓包和exe包调试用（console）
// import VConsole from "vconsole/dist/vconsole.min.js";
// if (process.env.NODE_ENV === "development") {
//   new VConsole();
//   localStorage.setItem("vConsole_switch_x", 500);
//   localStorage.setItem("vConsole_switch_y", 100);
// }

// 日期格式化过滤器
Vue.filter("date", (value, format) => {
  return dateFormat(value, format);
});
// 金钱格式化过滤器
Vue.filter("money", (value, unit) => {
  return moneyFormat(value, unit);
});

Vue.config.productionTip = false;

Vue.directive("permission", permissionDirective);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
