/**
 * 由于切换景区和退出登录需要清空数据，避免污染
 * 增删state的内容时要同时维护mutations内的resetState的方法
 * */
export default {
  // 选中的商户，通过header传给后台区分商户数据
  saleMerchantId: "", // 销售商户Id
  saleMerchantName: "", // 销售商户名称

  theme: localStorage.theme || "theme-default", // 主题配色变量
  productSettings: JSON.parse(localStorage.getItem("productSettings") || "[]"), // 产品设置

  currentFormat: "6", // 当前选中业态 // 业态String：1会员，2票务，3游乐，4剧院，5酒店，6餐饮，7零售，8租赁，9组合
  // 下单成功删除购物车
  deleteCart6: localStorage.deleteCart6 || true, // 票务6是否下单成功删除购物车
  deleteCart7: localStorage.deleteCart7 || true, // 游乐是否下单成功删除购物车
  deleteCart13: localStorage.deleteCart13 || true, // 组合是否下单成功删除购物车
  // 默认打印方式
  defaultPrintType6: localStorage.defaultPrintType6 || 1, // 票务2默认打印方式
  defaultPrintType7: localStorage.defaultPrintType7 || 1, // 游乐默认打印方式
  defaultPrintType13: localStorage.defaultPrintType13 || 1, // 组合默认打印方式
  // 自定义打印方式，注意下完单要清空
  customPrintType6: null, // 票务自定义打印方式
  customPrintType7: null, // 游乐自定义打印方式
  customPrintType13: null, // 组合自定义打印方式
  authorizeMenuVOList: [], // 按钮权限
  formatTypeList: JSON.parse(localStorage.formatTypeList || "[]"), // 页态权限
};
