/**
 * @name: 销售记录、核销记录，页面路由
 * @description:
 */

export default [
  {
    path: "/record/OrderDetail",
    name: "OrderDetail",
    component: () =>
      import(
        /* webpackChunkName: "chuck-record" */ "../views/record/OrderDetail"
      ),
  },
  // 会员积分余额
  {
    path: "/record/MemberBalance",
    name: "MemberBalance",
    component: () =>
      import(
        /* webpackChunkName: "chuck-record" */ "../views/record/MemberBalance"
      ),
  },
  // 会员优惠券
  {
    path: "/record/MemberCoupon",
    name: "MemberCoupon",
    component: () =>
      import(
        /* webpackChunkName: "chuck-record" */ "../views/record/MemberCoupon"
      ),
  },
  // 会员产品
  {
    path: "/record/MemberProduct",
    name: "MemberProduct",
    component: () =>
      import(
        /* webpackChunkName: "chuck-record" */ "../views/record/MemberProduct"
      ),
  },
  // 会员产品详情
  {
    path: "/record/MemberProductDetail",
    name: "MemberProductDetail",
    component: () =>
      import(
        /* webpackChunkName: "chuck-record" */ "../views/record/MemberProductDetail"
      ),
  },
  // 会员信息-余额-退款
  {
    path: "/record/MemberRefund",
    name: "MemberRefund",
    component: () =>
      import(
        /* webpackChunkName: "chuck-record" */ "../views/record/MemberRefund"
      ),
  },
  // 会员信息-余额-充值
  {
    path: "/record/RechargeCombo",
    name: "RechargeCombo",
    component: () =>
      import(
        /* webpackChunkName: "chuck-record" */ "../views/record/RechargeCombo"
      ),
  },
  // 会员信息-余额-充值套餐详情
  {
    path: "/record/ComboDetail",
    name: "ComboDetail",
    component: () =>
      import(
        /* webpackChunkName: "chuck-record" */ "../views/record/ComboDetail"
      ),
  },
  // 会员信息-余额-充值确认页
  {
    path: "/record/ConfirmOrder",
    name: "ConfirmOrder",
    component: () =>
      import(
        /* webpackChunkName: "chuck-record" */ "../views/record/ConfirmOrder"
      ),
  },
  // 订单详情
  {
    path: "/record/Orderdetails",
    name: "OrderDetails",
    component: () =>
      import(
        /* webpackChunkName: "chuck-record" */ "../views/record/OrderDetails"
      ),
  },
  // 明细
  {
    path: "/record/BalanceDetail",
    name: "BalanceDetail",
    component: () =>
      import(
        /* webpackChunkName: "chuck-record" */ "../views/record/BalanceDetail"
      ),
  },
  // 清账
  {
    path: "/record/CloseoutAccount",
    name: "CloseoutAccount",
    component: () =>
      import(
        /* webpackChunkName: "chuck-record" */ "../views/record/CloseoutAccount"
      ),
  },
  // 清账
  {
    path: "/record/CloseoutAccountList",
    name: "CloseoutAccountList",
    component: () =>
      import(
        /* webpackChunkName: "chuck-record" */ "../views/record/CloseoutAccountList"
      ),
  },
];
