/**
 * @name: 我的页面路由
 * @description:
 */

export default [
  {
    path: "/me/ColorSetting",
    name: "ColorSetting",
    // route level code-splitting 路由级代码分割
    // this generates a separate chunk (about.[hash].js) for this route 为该路由生成一个单独的块(about.[hash].js)
    // which is lazy-loaded when the route is visited. 当路由被访问时，它是惰性加载的。
    component: () =>
      import(/* webpackChunkName: "chuck-me" */ "../views/me/ColorSetting"),
  },
  // 热销产品设置
  {
    path: "/me/HotSaleProduct",
    name: "HotSaleProduct",
    component: () =>
      import(
        /* webpackChunkName: "chuck-tabbar" */ "../views/me/HotSaleProduct.vue"
      ),
  },
  // 查账交账
  {
    path: "/me/auditAndPayAccounts",
    name: "AuditAndPayAccounts",
    component: () =>
      import(
        /* webpackChunkName: "chuck-tabbar" */ "../views/me/AuditAndPayAccounts.vue"
      ),
  },
  // 查账交账 商户(产品)详情
  {
    path: "/me/auditAndPayAccountsDetails",
    name: "AuditAndPayAccountsDetails",
    component: () =>
      import(
        /* webpackChunkName: "chuck-tabbar" */ "../views/me/AuditAndPayAccountsDetails.vue"
      ),
  },
  // 交账 记录页面
  {
    path: "/me/paymentRecord",
    name: "PaymentRecord",
    component: () =>
      import(
        /* webpackChunkName: "chuck-tabbar" */ "../views/me/PaymentRecord.vue"
      ),
  },
  //
  {
    path: "/me/changePassword",
    name: "ChangePassword",
    component: () =>
      import(
        /* webpackChunkName: "chuck-tabbar" */ "../views/me/ChangePassword.vue"
      ),
  },
];
