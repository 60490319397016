<template>
  <div class="index" v-if="gotAuth">
    <router-view />
    <!--tabbar-->
    <van-tabbar
      placeholder
      v-model="active"
      :active-color="color"
      v-if="newVersion"
    >
      <van-tabbar-item icon="home-o" replace to="/">
        <img
          v-if="active === 0"
          slot="icon"
          :src="tabBarIcon.tabbarIconActive.me"
          alt=""
        />
        <img
          v-else
          slot="icon"
          src="../assets/images/tabbar/theme-default/sy.png"
          alt=""
        />
        首页
      </van-tabbar-item>
      <van-tabbar-item
        icon="search"
        replace
        to="/home-new/DirectPay"
        v-permission="'handsetPayOnly'"
      >
        <img
          v-if="active === 1"
          slot="icon"
          :src="tabBarIcon.tabbarIconActive.pay"
          alt=""
        />
        <img
          v-else
          slot="icon"
          src="../assets/images/tabbar/theme-default/zjsk.png"
          alt=""
        />
        直接收款
      </van-tabbar-item>
      <van-tabbar-item icon="setting-o" replace to="/me/MeIndex">
        <img
          v-if="active === 2"
          slot="icon"
          :src="tabBarIcon.tabbarIconActive.me"
          alt=""
        />
        <img
          v-else
          slot="icon"
          src="../assets/images/tabbar/theme-default/account.png"
          alt=""
        />
        我的</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>

<script>
// import { buttonAuth } from "../api/user";

import { buttonAuth } from "../api/user";

export default {
  name: "Home",

  data() {
    return {
      active: 0,
      newVersion: localStorage.newVersion !== "false",
      gotAuth: false,
    };
  },
  computed: {
    tabBarIcon() {
      return this.$store.getters["common/theme"];
    },
    color() {
      return this.$store.getters["common/color"].primary;
    },
  },

  created() {
    this.checkActive();
    this.getButtonAuth();
  },

  methods: {
    // 选中tabbar路由
    checkActive() {
      const pages = ["/", "/home-new/DirectPay", "/me/MeIndex"];
      this.active = pages.indexOf(this.$route.path);
    },

    // 获取权限接口
    getButtonAuth() {
      buttonAuth({}, { catch: false })
        .then(({ authorizeMenuVOList, formatTypeList }) => {
          // authorizeMenuVOList: 按钮权限，formatTypeList业态权限
          this.$store.commit(
            "common/setAuthorizeMenuVOList",
            authorizeMenuVOList
          );
          this.$store.commit("common/setFormatTypeList", formatTypeList);
          this.$store.commit(
            "common/currentFormat",
            formatTypeList[0]?.formatType || "6"
          );
          this.gotAuth = true;
        })
        .catch(({ status, message }) => {
          console.log(message);
          // 防止到了选择景区页面后没有授权获取景区token就退出，然后再进来会报权限不足
          if (status === "SYS-ERROR403") {
            localStorage.removeItem("token");
            this.$router.push("/login");
          } else {
            this.$dialog
              .alert({
                title: "提示",
                message: message || `连接异常(auth)`,
              })
              .then(() => {
                localStorage.removeItem("token");
                this.$router.push("/login");
              });
          }
        });
    },
  },
};
</script>
