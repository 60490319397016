/**
 * @name: tabbar页面路由
 * @description:
 */

export default [
  {
    path: "/",
    // name: "HomeIndex",
    // route level code-splitting 路由级代码分割
    // this generates a separate chunk (about.[hash].js) for this route 为该路由生成一个单独的块(about.[hash].js)
    // which is lazy-loaded when the route is visited. 当路由被访问时，它是惰性加载的。
    component: () =>
      import(
        /* webpackChunkName: "chuck-tabbar" */ "../views/home-new/HomeIndex"
      ),
    children: [
      // 票务业态
      {
        path: "",
        name: "SellTicket",
        component: () =>
          import(
            /* webpackChunkName: "chuck-tabbar" */ "../views/home-new/ticket/SellTicket"
          ),
      },
    ],
  },
  // 直接付款
  {
    path: "/home-new/DirectPay",
    name: "DirectPay",
    component: () =>
      import(
        /* webpackChunkName: "chuck-tabbar" */ "../views/home-new/DirectPay"
      ),
  },
  // 我的页面
  {
    path: "/me/MeIndex",
    name: "MeIndex",
    component: () =>
      import(/* webpackChunkName: "chuck-tabbar" */ "../views/me/MeIndex"),
  },
];
