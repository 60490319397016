<!--底部按钮组件-->
<template>
  <div>
    <footer class="footer">
      <slot></slot>
    </footer>

    <div class="placeholder" :style="{ height }"></div>
  </div>
</template>

<script>
export default {
  name: "FooterBar",
  props: {
    // 占位高度
    height: {
      type: String,
      default: "1rem",
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="less">
.footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
}
</style>
