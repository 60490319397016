/**
 * @name:MS-用户中心接口-3.0
 * @date:2021-03-24
 * @description:新表模块
 **/
import http from "../utils/fetch"; // 公共请求方法
const prefix = "/api/user";
/**
 *用户登录授权
 */
// user001-用户登录-云玉峰
export const login = (data) => {
  return http.post(`${prefix}/login`, data);
};
// user002-用户授权-云玉峰
export const authorize = (data) => {
  return http.post(`${prefix}/authorize`, data);
};
// user003-用户登录-云玉峰
export const logout = (data) => {
  return http.post(`${prefix}/logout`, data);
};

//user000-获取登录用户账号信息-云玉峰
export const getUserInfo = (data) => {
  return http.get(`${prefix}/find/user/account/info`, data);
};
//user000-设置密码-云玉峰
export const setPassword = (data) => {
  return http.post(`${prefix}/pwd/setting`, data);
};
//user024-用户详情查询-云玉峰
export const findUserDetail = (data) => {
  return http.get(`${prefix}/find/user/detail`, data);
};
// 获取用户信息和头像 景区管家接口
export const getUser = (data) => {
  return http.get(`${prefix}/find/user/account/info`, data);
};
/**
 *短信验证码登录
 */
//用户发送短信验证码登录
export const smsCodeLogin = (data) => {
  return http.post(`${prefix}/sms/code/login`, data);
};
// 手持机按钮权限接口  http://192.168.1.50:40001/project/41/interface/api/4429
export const buttonAuth = (data, config = {}) => {
  return http.get(`${prefix}/button/auth`, data, config);
};
