import axios from "axios";
import { Toast, Dialog } from "vant";
import store from "../store";
Toast.allowMultiple();

/**
 * @name: 全局AJAX请求类
 * @date: 2020-12-21
 * @author: 易远胜
 * @param: {String} url - 请求地址
 * @param: {Object} params - get和delete方法的请求参数
 * @param: {Object} data - post/put/add方法的请求参数
 * @param: {Object} config - axios的config(可以覆盖实例默认配置),
 *                  config.loading = false :该请求取消加载动画，如扫码支付二维码等待结果接口不需要加载动画
 *                  config.catch = false 公共方法不处理异常，留给页面处理
 * @return: {Object} Promise - 异步请求响应的参数
 */
class Http {
  constructor() {
    this.requestCount = 0; // 请求数量
    this.loading = null; // loading遮罩层
    this.showConfirm = false; // 展示登录失效弹窗
    this.timersLow = null; // 加载缓慢，一次性定时器
    this.timerClose = null; // 加载关闭一次性定时器
  }
  // 公共配置
  instance() {
    return {
      // baseURL: "http://localhost:3333",
      // baseURL: "http://127.0.0.1:10011",
      timeout: 60000,
      headers: {
        "open-d": localStorage.getItem("token"),
        "Access-Control-Expose-Headers": "hello-qmp-one",
        // 销售商户Id,即供应商Id
        "Sale-Merchant": store.getters["common/getSaleMerchant"],
      },
    };
  }
  // 转换成URLSearchParams对象形式
  paramsSerializer(params) {
    let str = "";
    for (let key in params) {
      if (Array.isArray(params[key])) {
        str += params[key].map((item) => `${key}=${item}`).join("&");
      } else if (
        typeof params[key] === "string" ||
        typeof params[key] === "number" ||
        typeof params[key] === "boolean"
      ) {
        str += `${key}=${params[key]}&`;
      }
    }
    if (/&$/.test(str)) str = str.substring(0, str.length - 1);
    return str;
  }
  // 添加数据
  add(url, data, config = {}) {
    return this.request(
      {
        url,
        method: "post",
        data,
        ...config,
      },
      "添加成功"
    );
  }
  // 删除数据
  delete(url, params = {}, config = {}) {
    return this.request(
      {
        url,
        method: "delete",
        params,
        ...config,
        paramsSerializer: this.paramsSerializer,
      },
      "删除成功"
    );
  }
  // 修改/编辑数据
  put(url, data, config = {}) {
    return this.request(
      {
        url,
        method: "put",
        data,
        ...config,
      },
      "修改成功"
    );
  }
  // 更新数据-核销/开启/关闭/特殊操作等需要自定义提示语的需求
  update(url, data, config = {}) {
    return this.request({
      url,
      method: "put",
      data,
      ...config,
    });
  }
  // 获取数据
  get(url, params = {}, config = {}) {
    return this.request({
      url,
      method: "get",
      params,
      ...config,
      // `paramsSerializer` 是一个负责 `params` 序列化的函数
      paramsSerializer: this.paramsSerializer,
    });
  }
  // 提交数据-需要自定义提示语的需求
  post(url, data = {}, config = {}) {
    return this.request({
      url,
      method: "post",
      data,
      ...config,
    });
  }
  // 通用请求方法
  request(config = {}, message) {
    // 是否展示全局loading动画（全局遮罩层）
    const loading = config.loading !== false;
    if (loading) {
      if (this.requestCount <= 0) {
        this.loading = Toast.loading({
          message: config.loadingMessage || "加载中...",
          forbidClick: true,
          duration: 0,
        });
        // 加载缓慢提示
        this.timersLow = setTimeout(() => {
          Toast.loading({
            message: config.loadingMessage || "加载缓慢...",
            forbidClick: true,
          });
          clearTimeout(this.timersLow);
          this.timersLow = null;
        }, 5000);
        // 关闭弹窗
        this.timerClose = setTimeout(() => {
          this.loading.clear();
          this.loading = null;
          clearTimeout(this.timerClose);
          this.timerClose = null;
        }, 60000);
      }
      this.requestCount++;
    }
    return new Promise((resolve, reject) => {
      axios
        .request({ ...this.instance(), ...config })
        .then(({ headers, data, status }) => {
          if (message) Toast.success(message);
          // 刷新token
          if (headers["hello-qmp-one"]) {
            localStorage.setItem("token", headers["hello-qmp-one"]);
          }
          // 200：get成功；201：提交成功；204：修改/删除成功
          if (status === 200 || status === 201 || status === 204) {
            resolve(data);
          } else {
            if (config.catch !== false) {
              Dialog.alert({
                title: "提示",
                message: `${data.message || "连接异常"}`,
              }).then(() => {});
            }
            reject(data);
          }
        })
        .catch(({ response }) => {
          // 如果有响应信息
          if (response) {
            // 刷新token
            if (response.headers["hello-qmp-one"]) {
              localStorage.setItem("token", response.headers["hello-qmp-one"]);
            }
            if (response.data) {
              const { message, status } = response.data;
              // 字符串类型：401为登录失效，403是无权限访问
              if (status === "SYS-ERROR401" || status === "SYS-ERROR402") {
                // 并发接口只弹一个登录失效弹框
                // 如果登录失效调用退出登录接口不会再有登录失效弹框
                // 如果不存在token也不弹窗
                if (
                  !this.showConfirm &&
                  response.config.url !== "/api/user/logout" &&
                  localStorage.getItem("token")
                ) {
                  Dialog.confirm({
                    title: "提示",
                    message: message,
                    confirmButtonText: "重新登录",
                    showCancelButton: false,
                  })
                    .then(() => {
                      this.showConfirm = false;
                      localStorage.removeItem("token");
                      location.href = "#/login";
                      // on confirm
                    })
                    .catch(() => {
                      // on cancel
                    });
                  this.showConfirm = true;
                }
              }
              // 刷新太频繁警告提示-如抢购
              else if (/GATEWAY/.test(status)) {
                Toast(message);
              }
              // 业务页面处理异常不弹窗 catch: false
              else if (config.catch !== false) {
                Dialog.alert({
                  title: "提示",
                  message: message,
                }).then(() => {});
              }
              reject(response.data);
            } else {
              const { status, statusText } = response;
              if (config.catch !== false) {
                Dialog.alert({
                  title: "提示",
                  message: `连接异常：${statusText}`,
                }).then(() => {});
              }
              reject({
                message: "连接异常",
                status,
              });
            }
          } else {
            Dialog.alert({
              title: "提示",
              message: "网络中断，请检查网络",
            }).then(() => {});
            reject({
              message: "网络中断，请检查网络",
              status: "",
            });
          }
        })
        .finally(() => {
          if (loading) this.requestCount--;
          if (this.requestCount <= 0 && this.loading) {
            clearTimeout(this.timersLow);
            clearTimeout(this.timerClose);
            this.timersLow = null;
            this.timerClose = null;
            this.loading.clear();
            this.loading = null;
          }
        });
    });
  }
}

export default new Http();
