<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  created() {
    if (!localStorage.getItem("token")) {
      this.$router.push("/login");
    }
    // else {
    //   // 在页面加载时读取sessionStorage里的状态信息
    //   if (sessionStorage.getItem("store")) {
    //     this.$store.replaceState(
    //       Object.assign(
    //         {},
    //         this.$store.state,
    //         JSON.parse(sessionStorage.getItem("store"))
    //       )
    //     );
    //   }
    //
    //   // 在页面刷新时将vuex里的信息保存到sessionStorage里
    //   // beforeunload事件在页面刷新时先触发
    //   window.addEventListener("beforeunload", () => {
    //     sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    //   });
    // }
  },
  mounted() {
    // 设置rem
    function setRem() {
      var deviceWidth = document.documentElement.clientWidth;
      if (deviceWidth > 768) deviceWidth = 768;
      document.documentElement.style.fontSize = deviceWidth / 7.5 + "px";
    }
    setRem();
    window.onresize = setRem;
  },
};
</script>

<style lang="less">
@import "assets/style/reset";
@import "assets/style/common";
@import "assets/iconfont/iconfont.css";
</style>
