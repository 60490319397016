/**
 * @name: 门票路由配置
 * @description:
 */

export default [
  {
    path: "/",
    // route level code-splitting 路由级代码分割
    // this generates a separate chunk (about.[hash].js) for this route 为该路由生成一个单独的块(about.[hash].js)
    // which is lazy-loaded when the route is visited. 当路由被访问时，它是惰性加载的。
    component: () =>
      import(/* webpackChunkName: "chuck-home" */ "../views/home/HomeIndex"),
    children: [
      // 核销页面
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/off/ChangeOff"
          ),
      },
      // 多张门票核销
      {
        path: "/home/TicketOff",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/off/TicketOff"
          ),
      },
      // 商户项目核销页面
      {
        path: "/home/ItemOff",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/off/ItemOff"
          ),
      },
    ],
  },
];
