<template>
  <!-- 
    通用 头部 NavBar
    @props : 
    //   navBar 高度
    navHeight: {
      type: Number,
      default: 66,
    },
    // 标题
    title: {
      type: String,
    },
    // 是否显示左侧内容
    showLeftText: {
      type: Boolean,
      default: false,
    },
    // 是否显示 左侧图标
    leftArrow: {
      type: Boolean,
      default: true,
    },
    // leftText 左侧文字内容
    leftText: {
        type: String
    }
    // rightText 右侧文字内容
    rightText: {
        type: String
    }
    // 自定义背景色
    bgColor: {
      type: String,
      default: "",
    }
    @event：leftClick  左侧按钮点击时触发
    @slot: 自定义插槽 left 左边、right 右边、title 标题
 -->
  <div>
    <div
      class="navbar placeholder ph-2x"
      :style="{
        'background-color': bgColor ? bgColor : color,
        height: navHeight + 'px',
      }"
    >
      <!-- 导航栏内容 -->
      <slot>
        <!-- 左边箭头 和内容 -->
        <div class="pd-width">
          <slot name="left">
            <div @click="back" class="flex-start">
              <van-icon
                v-if="leftArrow"
                name="arrow-left"
                size="20px"
                color="#fff"
                class="mr-x"
              />
              <span v-if="showLeftText" class="left-text">{{ leftText }}</span>
            </div>
          </slot>
        </div>
        <!-- 中间标题 -->
        <div>
          <slot name="title">
            <div class="title text-3">{{ title }}</div>
          </slot>
        </div>
        <!-- 右边内容 -->
        <div class="pd-width">
          <slot name="right">
            <div>
              {{ rightText }}
            </div>
          </slot>
        </div>
      </slot>
    </div>
    <div
      v-if="placeholder"
      :style="{
        height: navHeight + 'px',
      }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  props: {
    //   navBar 高度
    navHeight: {
      type: Number,
      default: 50,
    },
    // 标题
    title: {
      type: String,
    },
    // 是否显示左侧 图标内容
    showLeftText: {
      type: Boolean,
      default: false,
    },
    // 是否显示 左侧图标
    leftArrow: {
      type: Boolean,
      default: true,
    },
    leftText: {
      type: String,
    },
    rightText: {
      type: String,
    },
    // 背景色
    bgColor: {
      type: String,
      default: "",
    },
    // 是否占位
    placeholder: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    color() {
      return this.$store.getters["common/color"].primary;
    },
  },
  methods: {
    back() {
      this.$emit("left-click");
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.navbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left-text {
    font-size: 16px;
    color: #fff;
  }
  .title {
    font-size: 18px;
    color: #fff;
  }
  .pd-width {
    width: 40px;
  }
}
.placeholder {
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  z-index: 1;
}
</style>
