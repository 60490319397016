export default {
  // 设置销售商户信息
  setSaleMerchant(state, { saleMerchantId, saleMerchantName }) {
    state.saleMerchantId = saleMerchantId;
    state.saleMerchantName = saleMerchantName;
  },
  // 重置数据
  resetState(state) {
    state.saleMerchantId = "";
    state.saleMerchantName = "";
  },
  // 改变主题色
  changeTheme(state, { theme, themeName }) {
    state.theme = theme || "theme-default";
    localStorage.setItem("theme", themeName);
  },
  // 产品设置
  productSetting(state, productSettings) {
    state.productSettings = productSettings || [];
    localStorage.setItem("productSettings", JSON.stringify(productSettings));
  },

  // 设置默认打印规则
  defaultPrintType(state, type) {
    state[`defaultPrintType${state.currentFormat}`] = type;
    localStorage.setItem(`defaultPrintType${state.currentFormat}`, type);
  },
  // 设置自定义打印规则
  customPrintType(state, type) {
    state[`customPrintType${state.currentFormat}`] = type;
    localStorage.setItem(`customPrintType${state.currentFormat}`, type);
  },
  // 设置下单成功清除购物车
  deleteCart(state, type) {
    state[`deleteCart${state.currentFormat}`] = type;
    localStorage.setItem(`deleteCart${state.currentFormat}`, type);
  },
  currentFormat(state, type) {
    state.currentFormat = type;
  },
  // 页态权限
  setFormatTypeList(state, data) {
    state.formatTypeList = data;
    localStorage.setItem("formatTypeList", JSON.stringify(data));
  },
  // 按钮权限
  setAuthorizeMenuVOList(state, data) {
    state.authorizeMenuVOList = data;
  },
};
