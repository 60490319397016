import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/Index.vue";

import login from "./login"; // 登录
import loginOld from "./login-old"; // 登录
import tabbar from "./tabbar"; // tabbar页面
import home from "./home"; // 门票、核销
import homeNew from "./home-new"; // 新版首页路由
import record from "./record"; // 销售记录
import me from "./me"; // 我的

Vue.use(VueRouter);

// 新旧版入口区分
const oldRouter = [
  ...home,
  ...loginOld,
  // 重定向
  {
    path: "*",
    redirect: "/",
  },
];

// 新版路由
const newRouter = [
  {
    path: "/",
    component: Index,
    children: [...tabbar],
  },
  ...homeNew,
  ...record,
  ...me,
  ...login,
  // 重定向
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  routes: localStorage.newVersion === "true" ? newRouter : oldRouter,
});

export default router;
