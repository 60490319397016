/**
 * @name: 登录页面路由
 * @description:
 */

export default [
  {
    path: "/login",
    name: "login",
    // route level code-splitting 路由级代码分割
    // this generates a separate chunk (about.[hash].js) for this route 为该路由生成一个单独的块(about.[hash].js)
    // which is lazy-loaded when the route is visited. 当路由被访问时，它是惰性加载的。
    component: () =>
      import(/* webpackChunkName: "chuck-login" */ "../views/login/LoginIndex"),
  },
  {
    path: "/login/MerchantList",
    name: "MerchantList",
    component: () =>
      import(
        /* webpackChunkName: "chuck-login" */ "../views/login/MerchantList"
      ),
  },
];
