/**
 *@name:主题色变量
 *@date: 2021-7-6
 *@author: 易远胜
 *@description: 根据后台模板配置的变量动态获取对应的主题对象，缓存在vuex中，每个页面都可使用
 */
// theme-default
import me from "../assets/images/tabbar/theme-default/wode1.png";
import home from "../assets/images/tabbar/theme-default/shouye.png";
import order from "../assets/images/tabbar/theme-default/dd1.png";
import pay from "../assets/images/tabbar/theme-default/yue1.png";
// theme-blue-dark
import methemebluedark from "../assets/images/tabbar/theme-blue-dark/wode1.png";
import homethemebluedark from "../assets/images/tabbar/theme-blue-dark/shouye.png";
import orderthemebluedark from "../assets/images/tabbar/theme-blue-dark/dd1.png";
import paythemebluedark from "../assets/images/tabbar/theme-blue-dark/yue1.png";
// theme-red
import methemered from "../assets/images/tabbar/theme-red/wode1.png";
import homethemered from "../assets/images/tabbar/theme-red/shouye.png";
import orderthemered from "../assets/images/tabbar/theme-red/dd1.png";
import paythemered from "../assets/images/tabbar/theme-red/yue1.png";
// theme-light
import methemelight from "../assets/images/tabbar/theme-light/wode1.png";
import homethemelight from "../assets/images/tabbar/theme-light/shouye.png";
import orderthemelight from "../assets/images/tabbar/theme-light/dd1.png";
import paythemelight from "../assets/images/tabbar/theme-light/yue1.png";
// theme-green
import methemegreen from "../assets/images/tabbar/theme-green/wode1.png";
import homethemegreen from "../assets/images/tabbar/theme-green/shouye.png";
import orderthemegreen from "../assets/images/tabbar/theme-green/dd1.png";
import paythemegreen from "../assets/images/tabbar/theme-green/yue1.png";
// theme-green-dark
import methemegreendark from "../assets/images/tabbar/theme-green-dark/wode1.png";
import homethemegreendark from "../assets/images/tabbar/theme-green-dark/shouye.png";
import orderthemegreendark from "../assets/images/tabbar/theme-green-dark/dd1.png";
import paythemegreendark from "../assets/images/tabbar/theme-green-dark/yue1.png";
// theme-dark
import methemedark from "../assets/images/tabbar/theme-dark/wode1.png";
import homethemedark from "../assets/images/tabbar/theme-dark/shouye.png";
import orderthemedark from "../assets/images/tabbar/theme-dark/dd1.png";
import paythemedark from "../assets/images/tabbar/theme-dark/yue1.png";
// theme-orange
import methemeorange from "../assets/images/tabbar/theme-orange/wode1.png";
import homethemeorange from "../assets/images/tabbar/theme-orange/shouye.png";
import orderthemeorange from "../assets/images/tabbar/theme-orange/dd1.png";
import paythemeorange from "../assets/images/tabbar/theme-orange/yue1.png";
// theme-purple
import methemepurple from "../assets/images/tabbar/theme-purple/wode1.png";
import homethemepurple from "../assets/images/tabbar/theme-purple/shouye.png";
import orderthemepurple from "../assets/images/tabbar/theme-purple/dd1.png";
import paythemepurple from "../assets/images/tabbar/theme-purple/yue1.png";
// theme-gray
import methemegray from "../assets/images/tabbar/theme-gray/wode1.png";
import homethemegray from "../assets/images/tabbar/theme-gray/shouye.png";
import orderthemegray from "../assets/images/tabbar/theme-gray/dd1.png";
import paythemegray from "../assets/images/tabbar/theme-gray/yue1.png";
export default {
  // 畅游橙-默认主题
  "theme-default": {
    primary: "#386CDF", // 常规
    tabbarIconActive: {
      me,
      home,
      order,
      pay,
    },
  },
  // 深蓝
  "theme-blue-dark": {
    primary: "#3B49C7",
    tabbarIconActive: {
      me: methemebluedark,
      home: homethemebluedark,
      order: orderthemebluedark,
      pay: paythemebluedark,
    },
  },
  // 畅游红a
  "theme-red": {
    primary: "#ff4242",
    tabbarIconActive: {
      me: methemered,
      home: homethemered,
      order: orderthemered,
      pay: paythemered,
    },
  },
  // 浅红
  "theme-light": {
    primary: "#FF5F46",
    tabbarIconActive: {
      me: methemelight,
      home: homethemelight,
      order: orderthemelight,
      pay: paythemelight,
    },
  },
  // 绿色
  "theme-green": {
    primary: "#19B81E",
    tabbarIconActive: {
      me: methemegreen,
      home: homethemegreen,
      order: orderthemegreen,
      pay: paythemegreen,
    },
  },
  // 深绿
  "theme-green-dark": {
    primary: "#2C9E6C",
    tabbarIconActive: {
      me: methemegreendark,
      home: homethemegreendark,
      order: orderthemegreendark,
      pay: paythemegreendark,
    },
  },
  // 深色
  "theme-dark": {
    primary: "#354065",
    tabbarIconActive: {
      me: methemedark,
      home: homethemedark,
      order: orderthemedark,
      pay: paythemedark,
    },
  },
  // 橙色
  "theme-orange": {
    primary: "#F1A41A",
    tabbarIconActive: {
      me: methemeorange,
      home: homethemeorange,
      order: orderthemeorange,
      pay: paythemeorange,
    },
  },
  // 紫色
  "theme-purple": {
    primary: "#715DC2",
    tabbarIconActive: {
      me: methemepurple,
      home: homethemepurple,
      order: orderthemepurple,
      pay: paythemepurple,
    },
  },
  // 灰色
  "theme-gray": {
    primary: "#949CA4",
    tabbarIconActive: {
      me: methemegray,
      home: homethemegray,
      order: orderthemegray,
      pay: paythemegray,
    },
  },
};
