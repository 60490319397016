import store from "../store";

/**
 * @description 判断当前权限是否存在
 */
function hasPermission(currentPermission) {
  const currentBtnPermissionList =
    store.getters["common/getAuthorizeMenuVOList"];
  // 如果没有配置按钮权限和业态权限，则认为是超级管理员
  if (
    (!currentBtnPermissionList || !currentBtnPermissionList.length) &&
    !store.state.common.formatTypeList.length
  ) {
    return true;
  }

  return currentBtnPermissionList.some((item) => item === currentPermission);
}

export default {
  inserted(el, binding) {
    if (!hasPermission(binding.value)) {
      // 放开权限时打开  (放开权限时搜索"放开权限时打开" 有多个地方需要打开)
      el.parentNode.removeChild(el);
    }
  },
};
