/**
 * @name: 新版首页页面路由
 * @description:
 */

export default [
  // 搜索
  {
    path: "/home-new/TheSearch",
    name: "TheSearch",
    component: () =>
      import(
        /* webpackChunkName: "chuck-home-new" */ "../views/home-new/TheSearch"
      ),
  },
];
