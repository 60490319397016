import themeColor from "../../utils/theme-color"; // js主题色集合
export default {
  // 获取商户信息，header请求信息用
  getSaleMerchant(state) {
    let saleMerchant = "";
    if (state.saleMerchantId && state.saleMerchantName) {
      saleMerchant = `${state.saleMerchantId},${encodeURIComponent(
        state.saleMerchantName
      )}`;
    }
    return saleMerchant;
  },

  // 获取主题色变量
  color(state) {
    return themeColor[state.theme] || themeColor["theme-default"];
  },
  // 获取主题色 title
  theme(state) {
    return themeColor[state.theme];
  },

  // 当前业态默认打印方式
  defaultPrintType(state) {
    if (state.currentFormat) {
      return Number(state[`defaultPrintType${state.currentFormat}`]);
    } else {
      return 1;
    }
  },
  // 当前自定义打印方式,
  customPrintType(state) {
    if (state.currentFormat) {
      return Number(state[`customPrintType${state.currentFormat}`]);
    } else {
      return "";
    }
  },
  // 是否下单成功清空购物车
  deleteCart(state) {
    if (state.currentFormat) {
      return state[`deleteCart${state.currentFormat}`];
    } else {
      return true;
    }
  },
  // 获取购物车列表
  getCartList(state) {
    return state.cart;
  },

  // 获取菜单权限key集合
  getAuthorizeMenuVOList(state) {
    return state.authorizeMenuVOList.map((item) => item.menuKey);
  },
  // 是否是超级管理员 | 按钮和业态都没有设置则为超级管理员
  isSupperManager(state) {
    return (
      state.authorizeMenuVOList.length === 0 &&
      state.formatTypeList.length === 0
    );
  },
  // 选中页态
  currentFormat(state) {
    return state.currentFormat;
  },
};
